import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import Button from "../../components/new-ui/button"
import InputField from "../../components/new-ui/input-field"

const OrderNumberForm = ({ onSubmit, requestingReturn }) => {
  const { t } = useTranslation()
  const FormSchema = Yup.object().shape({
    orderNumber: Yup.number().required("Required"),
    zipCode: Yup.string()
      .min(2, "Too Short!")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
  })

  const formik = useFormik({
    initialValues: {
      orderNumber: "",
      email: "",
      zipCode: "",
    },

    validationSchema: FormSchema,
    validateOnChange: true,
    onSubmit: values => {
      onSubmit(values)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-input-field">
        <InputField
          id="orderNumber"
          type="string"
          name="orderNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.orderNumber}
          placeholder={t("order_number")}
          error={
            formik.errors.orderNumber && formik.touched.orderNumber ? (
              <div className="error">{t("only_numbers_error")}</div>
            ) : null
          }
        />
      </div>

      <div className="form-input-field">
        <InputField
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          placeholder={t("email")}
          error={
            formik.errors.email && formik.touched.email ? (
              <div className="error">{t("invalid_email_error")}</div>
            ) : null
          }
        />
      </div>
      <div className="form-input-field">
        <InputField
          id="zipCode"
          name="zipCode"
          type="string"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.zipCode}
          placeholder={t("postal")}
          error={
            formik.errors.zipCode && formik.touched.zipCode ? (
              <div className="error">{t("only_numbers_error")}</div>
            ) : null
          }
        />
      </div>

      <Button
        type="submit"
        disabled={
          (formik.errors.email || formik.errors.orderNumber,
          formik.errors.zipCode) ||
          !formik.values.zipCode ||
          !formik.values.email ||
          !formik.values.orderNumber
        }
        sx={{
          width: "100%",
        }}
      >
        {t("continue")}
      </Button>
    </form>
  )
}

export default OrderNumberForm
